"use strict";

var _interopRequireDefault = require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _typeof2 = _interopRequireDefault(require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/typeof.js"));
var _index = require("./index");
var moment = require('moment');
var _default = exports.default = {
  deepClone: function deepClone(oldObj) {
    // 对象数组深拷贝方法
    // return obj && JSON.parse(JSON.stringify(obj));
    var newObj = {};
    if (oldObj instanceof Array) {
      newObj = [];
    }
    for (var key in oldObj) {
      var value = oldObj[key];
      if ((0, _typeof2.default)(value) === 'object' && value !== null) {
        newObj[key] = this.deepClone(value);
      } else {
        newObj[key] = value;
      }
    }
    return newObj;
  },
  isDecimal: function isDecimal(s) {
    // 验证非负整数或小数[小数最多精确到小数点后两位]
    var reg = /^[0-9]+([.]{1}[0-9]{1,2})?$/;
    return reg.test(s);
  },
  isDecimalOne: function isDecimalOne(s) {
    // 验证非负整数或小数[小数最多精确到小数点后一位]
    var reg = /^[0-9]+([.]{1}[0-9]{1})?$/;
    return reg.test(s);
  },
  isPositiveInteger: function isPositiveInteger(s) {
    // 非负整数（0到n)
    var reg = /^[0-9]+$/;
    return reg.test(s);
  },
  trim: function trim(s) {
    // 去掉前后空格
    return s.replace(/^\s+|\s+$/gm, '');
  },
  timeIsBefore: function timeIsBefore(start, end) {
    var date = '2019-09-09 ';
    var check = moment(date + start).isBefore(date + end);
    return check;
  },
  formatIdCard: function formatIdCard(idcard) {
    if (idcard.length === 15) {
      return idcard.replace(/(\d{6})\d{6}(\d{3})/, '$1******$2');
    } else {
      return idcard.replace(/(\d{5})\d{6}(\d{6})/, '$1******$2');
    }
  },
  checkIdCard: function checkIdCard(idcode) {
    // 加权因子
    var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    // 校验码
    var check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
    var code = idcode + '';
    var last = idcode[17]; //最后一位

    var seventeen = code.substring(0, 17);

    // ISO 7064:1983.MOD 11-2
    // 判断最后一位校验码是否正确
    var arr = seventeen.split('');
    var len = arr.length;
    var num = 0;
    for (var i = 0; i < len; i++) {
      num = num + arr[i] * weight_factor[i];
    }

    // 获取余数
    var resisue = num % 11;
    var last_no = check_code[resisue];

    // 格式的正则
    // 正则思路
    /*
    第一位不可能是0
    第二位到第六位可以是0-9
    第七位到第十位是年份，所以七八位为19或者20
    十一位和十二位是月份，这两位是01-12之间的数值
    十三位和十四位是日期，是从01-31之间的数值
    十五，十六，十七都是数字0-9
    十八位可能是数字0-9，也可能是X
    */
    var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

    // 判断格式是否正确
    var format = idcard_patter.test(idcode);

    // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
    return last === last_no && format ? true : false;
  },
  getDevTypeName: function getDevTypeName(showType) {
    // 获取设备展示名称
    var devTypeDict = (0, _index.getSession)('devTypeDict');
    var whiteArray = ['ALARM_EQUIPMENT', 'OTHER_DEVICE'];
    var name = devTypeDict[showType];
    if (whiteArray.includes(showType) || name.indexOf('设备') === -1) {
      return name;
    } else {
      return name.slice(0, name.length - 2);
    }
  },
  isExternal: function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
  }
};