"use strict";

var _interopRequireDefault = require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _index = require("@/utils/index");
var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _IconMenu = _interopRequireDefault(require("./IconMenu"));
var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));
var _business = _interopRequireDefault(require("@/api/modules/business"));
var _router = _interopRequireDefault(require("@/router"));
var _default = exports.default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default,
    iconMenu: _IconMenu.default
  },
  data: function data() {
    return {
      menuList: [],
      permission_routes: [],
      projectOptions: [],
      projectId: Number(this.$store.state.user.projectId),
      superAdmin: this.$store.state.user.isSuperAdmin === 'true',
      hasProjectIdList: (0, _index.getSession)('userProjectIdList') || '',
      isCollapse: false
    };
  },
  computed: {
    // ...mapGetters([
    //   'permission_routes',
    //   'sidebar'
    // ]),
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      // return this.$store.state.settings.sidebarLogo
      return '';
    },
    variables: function variables() {
      return _variables2.default;
    } // isCollapse() {
    //   return !this.sidebar.opened
    // }
  },
  created: function created() {
    if (this.hasProjectIdList) {
      this.hasProjectIdList = this.formatArr(this.hasProjectIdList);
    }
    this.permission_routes = _router.default.options.routes;
  },
  mounted: function mounted() {},
  methods: {
    formatArr: function formatArr(data) {
      var newdata = [];
      if (!data) {
        newdata = [];
      } else if (data.indexOf(',') !== -1) {
        newdata = data.split(',');
      } else {
        newdata = [data];
      }
      return newdata;
    }
  }
};