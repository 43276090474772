"use strict";

var _interopRequireDefault = require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _requireIcons = _interopRequireDefault(require("./requireIcons"));
var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));
var _default = exports.default = {
  name: 'Icons',
  data: function data() {
    return {
      iconsMap: _requireIcons.default
    };
  },
  methods: {
    generateIconCode: function generateIconCode(symbol) {
      return "<svg-icon icon-class=\"".concat(symbol, "\" />");
    },
    handleClipboard: function handleClipboard(text, event) {
      (0, _clipboard.default)(event, text);
    }
  }
};