"use strict";

var _interopRequireDefault = require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find-index");
var _router = require("@/router");
var _lodash = _interopRequireDefault(require("lodash"));
var _addroute = _interopRequireDefault(require("@/router/modules/addroute"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  // if(['dataManagement', 'permissionManagement', 'settingsManagement'].indexOf(route.name) !== -1){
  //   return 1
  // }
  if (!route.needAuth) {
    return {
      index: 1
    };
  } else {
    var index = _lodash.default.findIndex(roles, {
      'menuUrl': route.name
    });
    var item = _lodash.default.find(roles, {
      'menuUrl': route.name
    });
    return {
      index: index,
      item: item
    };
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles, newroutes) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    var hasPer = hasPermission(roles, tmp);
    if (hasPer.index !== -1) {
      tmp.meta.menuId = hasPer.item ? hasPer.item.id : ''; // 设置菜单id
      tmp.meta.rights = hasPer.item ? hasPer.item.rights : ''; // 设置功能权限
      if (tmp.children) {
        if (tmp.name === 'dataManagement' && newroutes) {
          var newroute = (0, _addroute.default)(newroutes, roles);
          tmp.children = tmp.children.concat(newroute);
        } else {
          tmp.children = filterAsyncRoutes(tmp.children, roles);
        }
        if (tmp.children.length) {
          tmp.children[0].meta.menuId = hasPer.item ? hasPer.item.id : ''; // 设置菜单id
          tmp.children[0].meta.rights = hasPer.item ? hasPer.item.rights : ''; // 设置功能权限
        }
      }
      if (tmp.name === 'dataManagement' && tmp.children.length) {
        res.push(tmp);
      }
      if (tmp.name !== 'dataManagement') {
        res.push(tmp);
      }
    }
  });
  return res;
}

/**
 * 动态添加子路由
 * @param routes asyncRoutes
 * @param roles
 */
function addAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (tmp.name === 'dataManagement') {
      var newroute = (0, _addroute.default)(roles);
      tmp.children = tmp.children.concat(newroute);
    }
    res.push(tmp);
  });
  return res;
}
var permission = {
  state: {
    routes: [],
    addRoutes: []
    // hasWarnManagePermission: 0,
    // backendManageWhiteList: ['admin']
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = _router.constantRoutes.concat(routes);
    },
    CLEAR_ROUTES: function CLEAR_ROUTES(state) {
      state.addRoutes = [];
      state.routes = [];
    }
  },
  actions: {
    generateRoutes: function generateRoutes(_ref, data) {
      var commit = _ref.commit;
      var roles = data.roles;
      var newroutes = data.newroutes;
      return new Promise(function (resolve) {
        var newasyncRoutes = Number(localStorage.projectId) === 2 ? _router.asyncRoutes.fuzhou : _router.asyncRoutes.heze;
        var accessedRoutes = filterAsyncRoutes(newasyncRoutes, roles, newroutes);
        commit('SET_ROUTES', accessedRoutes);
        resolve(accessedRoutes);
      });
    },
    clearRoutes: function clearRoutes(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve) {
        commit('CLEAR_ROUTES');
        resolve();
      });
    },
    addRoutes: function addRoutes(_ref3, data) {
      var commit = _ref3.commit;
      var roles = data.roles;
      var newroutes = data.newroutes;
      return new Promise(function (resolve) {
        var newasyncRoutes = Number(localStorage.projectId) === 2 ? _router.asyncRoutes.fuzhou : _router.asyncRoutes.heze;
        var addRoutes = addAsyncRoutes(newasyncRoutes, roles, newroutes);
        commit('SET_ROUTES', addRoutes);
        resolve(addRoutes);
      });
    }
  }
};
var _default = exports.default = permission;