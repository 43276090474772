"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
/** 管理后台角色管理 */
var _default = exports.default = {
  /**
   * 新增/修改角色
   */
  editOrAddRole: function editOrAddRole(data) {
    return (0, _http.POST)('/business/bgUserGroup/editUserGroup', data);
  },
  /**
   * 角色列表
  */
  getRoleList: function getRoleList(data) {
    return (0, _http.POST)('/business/bgUserGroup/findList', data);
  },
  /**
   * 删除角色
  */
  deleteRole: function deleteRole(data) {
    return (0, _http.GET)('/business/bgUserGroup/deleteUserGroup', data);
  },
  /**
   * 权限设置（含编辑详情）
  */
  rolePermission: function rolePermission(data) {
    return (0, _http.GET)('/business/bgUserGroup/getUserGroupDetail', data);
  },
  /**
   * 修改权限设置
  */
  editRolePermission: function editRolePermission(data) {
    return (0, _http.POST)('/business/bgUserGroup/editUserGroupSet', data);
  },
  /**
   * 获取数据菜单下拉列表
  */
  getDateMenuList: function getDateMenuList(data) {
    return (0, _http.GET)('/business/bgUserGroup/getDateMenuList', data);
  },
  /**
   * 获取菜单权限
  */
  getAllMenu: function getAllMenu(data) {
    return (0, _http.GET)('/business/bgUserGroup/getAllMenu', data);
  },
  /**
   * 菜单保存
  */
  saveAllMenu: function saveAllMenu(data) {
    return (0, _http.POST)('/business/bgUserGroup/saveAllMenu', data);
  },
  /**
   * 角色
  */
  getUserCountByRole: function getUserCountByRole(data) {
    return (0, _http.POST)('/schoolUser/getUserCountByRole', data);
  },
  /**
   * 学校、用户。。。
  */
  queryUserSchoolCourseList: function queryUserSchoolCourseList(data) {
    return (0, _http.POST)('/schoolUser/queryUserSchoolCourseList', data);
  },
  /**
   * 用户——新增
  */
  userAdd: function userAdd(data) {
    return (0, _http.POST)('/schoolUser/insert', data);
  },
  /**
   * 用户——编辑
  */
  userEdit: function userEdit(data) {
    return (0, _http.POST)('/schoolUser/update', data);
  },
  /**
   * 用户——删除
   */
  userDel: function userDel(data) {
    return (0, _http.POST)("/schoolUser/deleteById?id=".concat(data.id), data);
  }
};