"use strict";

var _interopRequireDefault = require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _form = _interopRequireDefault(require("@/components/form"));
var _default = exports.default = {
  name: 'user',
  components: {
    formWrap: _form.default
  },
  data: function data() {
    return {
      isLoading: false,
      emptyText: '加载中...',
      tableColumn: [{
        title: '姓名',
        prop: 'userName',
        minWidth: '100',
        width: null
      }, {
        title: '角色权限',
        prop: 'roleId',
        minWidth: '',
        width: null
      }, {
        title: '学校',
        prop: 'schoolName',
        minWidth: '',
        width: null
      }, {
        title: '课程权限',
        prop: 'courseTitles',
        minWidth: '',
        width: null
      }, {
        title: '操作',
        prop: 'operation',
        minWidth: '100',
        width: ''
      }],
      tableData: [],
      total: 0,
      searchParams: {
        currentPage: 1,
        pageSize: 20
      },
      id: '',
      title: '添加',
      schoolList: []
    };
  },
  mounted: function mounted() {
    this.getDataList();
    this.getSchoolList();
  },
  methods: {
    /* 获取列表数据 */getDataList: function getDataList(currentPage) {
      var _this = this;
      this.isLoading = true;
      this.searchParams.currentPage = currentPage || 1;
      var param = {
        currentPage: this.searchParams.currentPage,
        pageSize: this.searchParams.pageSize,
        id: this.id
      };
      this.$api.role.queryUserSchoolCourseList(param).then(function (res) {
        _this.isLoading = false;
        _this.total = res.total;
        _this.tableData = res.data || [];
        _this.emptyText = !_this.tableData.length ? '暂无数据' : _this.emptyText;
      });
    },
    /**
    * 获取学校列表
    **/
    getSchoolList: function getSchoolList() {
      var _this2 = this;
      var param = {
        currentPage: 1,
        pageSize: 999
      };
      this.$api.basic.querySchoolCourseUserCountList(param).then(function (res) {
        _this2.schoolList = res.data || [];
        if (_this2.schoolList.length) {
          _this2.schoolList.forEach(function (item) {
            _this2.$set(item, 'label', item.schoolName);
            _this2.$set(item, 'value', item.id);
          });
        }
      });
    },
    /* 新增&编辑 */add: function add(type, obj) {
      var select = [{
        label: '老师',
        value: 2
      }, {
        label: '学生',
        value: 3
      }, {
        label: '游客',
        value: 4
      }];
      var labelList = [{
        label: '人员名称',
        prop: 'userName',
        type: 'input',
        required: true
      }, {
        label: '角色',
        prop: 'roleId',
        type: 'select',
        required: true,
        select: select
      }, {
        label: '学校名称',
        prop: 'schoolId',
        type: 'select',
        required: true,
        select: this.schoolList
      }, {
        label: '手机号码',
        prop: 'mobilePhone',
        type: 'input',
        required: obj ? false : true,
        disabled: obj ? true : false
      }, {
        label: '备注',
        prop: 'remark',
        type: 'input',
        required: false
      }];
      if (type === 'add') {
        this.title = '添加';
        this.$refs.form.showDialog(labelList);
      } else {
        this.title = '编辑';
        this.$refs.form.showForm(obj, labelList);
      }
    },
    submit: function submit(form) {
      var _this3 = this;
      var param = {
        id: form.id ? form.id : '',
        userId: form.userId,
        userName: form.userName,
        roleId: form.roleId,
        schoolId: form.schoolId,
        mobilePhone: form.mobilePhone,
        remark: form.remark
      };
      if (form.id) {
        this.$api.role.userEdit(param).then(function (res) {
          if (res.status.code === 200) {
            _this3.$message.success('编辑成功');
            _this3.$refs.form.cancel();
            _this3.getDataList();
          }
        });
      } else {
        this.$api.role.userAdd(param).then(function (res) {
          if (res.status.code === 200) {
            _this3.$message.success('添加成功');
            _this3.$refs.form.cancel();
            _this3.getDataList();
          }
        });
      }
    },
    /* 删除 */handelDelete: function handelDelete(obj) {
      var _this4 = this;
      this.$confirm("\u786E\u5B9A\u8981\u5220\u9664".concat(obj.userName, "\u8FD9\u4E2A\u6210\u5458\u5417?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.$api.role.userDel({
          id: obj.id
        }).then(function () {
          _this4.$message({
            type: 'success',
            message: "\u5220\u9664\u6210\u529F!"
          });
          _this4.getDataList();
        });
      }).catch(function () {});
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchParams.currentPage = val;
      this.getDataList(val);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.searchParams.pageSize = val;
      this.getDataList();
    },
    getRole: function getRole(val) {
      if (val === 2) {
        return '老师';
      }
      if (val === 3) {
        return '学生';
      }
      if (val === 4) {
        return '其他';
      }
    }
  }
};