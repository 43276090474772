"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-box course-detail"
  }, [_c("div", {
    staticClass: "detail-tree pt-15 h-100 mr-15"
  }, [_c("div", {
    staticClass: "mb-15 flex-box-justify-space-between"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      size: "small",
      disabled: !this.treeData.length
    },
    on: {
      click: function click($event) {
        return _vm.addCatalogue("peer");
      }
    }
  }, [_vm._v("同级目录")]), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      size: "small",
      disabled: _vm.childBtnDisabled
    },
    on: {
      click: function click($event) {
        return _vm.addCatalogue("child");
      }
    }
  }, [_vm._v("子目录")])], 1), _c("div", {
    staticClass: "overview-text mb-15 can-click",
    class: {
      active: _vm.overviewActive
    },
    on: {
      click: function click($event) {
        return _vm.clickOverView();
      }
    }
  }, [_c("span", [_vm._v("课程总览")]), _c("span", [_c("svg-icon", {
    staticClass: "can-click",
    attrs: {
      "icon-class": "note"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.editCatalogue();
      }
    }
  }), _c("svg-icon", {
    staticClass: "can-click",
    attrs: {
      "icon-class": "delete"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.delCatalogue();
      }
    }
  })], 1)]), _c("el-tree", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.treeLoading,
      expression: "treeLoading"
    }],
    staticClass: "i-tree course-detail-tree",
    attrs: {
      "highlight-current": _vm.highlightCurrent,
      data: _vm.treeData,
      props: _vm.defaultProps,
      "default-expand-all": ""
    },
    on: {
      "node-click": _vm.nodeClick
    }
  })], 1), _c("div", {
    staticClass: "detail-wrap formBox2"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.overviewShow,
      expression: "overviewShow"
    }]
  }, [_c("div", {
    staticClass: "course-list-text"
  }, [_vm._v("基础信息")]), _c("el-form", {
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      model: _vm.formData,
      rules: _vm.formRules,
      inline: "",
      "label-width": "100px"
    }
  }, [_c("div", [_c("el-form-item", {
    attrs: {
      label: "课程封面",
      prop: "courseImgUrl"
    }
  }, [_c("el-upload", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.imgLoading,
      expression: "imgLoading"
    }],
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      "show-file-list": false,
      "http-request": _vm.uploadImage,
      "before-upload": _vm.overImgBefore
    }
  }, [_vm.suffix === "mp4" && _vm.formData.courseImgUrl ? _c("video", {
    staticClass: "upload-video",
    attrs: {
      src: _vm.formData.courseImgUrl
    }
  }) : _vm.suffix !== "mp4" && _vm.formData.courseImgUrl ? _c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.formData.courseImgUrl
    }
  }) : _c("i", {
    staticClass: "el-icon-plus avatar-uploader-icon"
  }), _vm.formData.courseImgUrl ? _c("i", {
    staticClass: "el-icon-remove",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        _vm.formData.courseImgUrl = "";
      }
    }
  }) : _vm._e()])], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "课程名称",
      prop: "courseTitle"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "323px"
    },
    attrs: {
      placeholder: "请输入课程名称",
      maxlength: "60",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.courseTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "courseTitle", $$v);
      },
      expression: "formData.courseTitle"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "课程分类",
      prop: "courseTypeId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "389px"
    },
    attrs: {
      placeholder: "请选择分类",
      clearable: ""
    },
    model: {
      value: _vm.formData.courseTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "courseTypeId", $$v);
      },
      expression: "formData.courseTypeId"
    }
  }, _vm._l(_vm.classificationSelect, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.courseType,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", [_c("el-form-item", {
    attrs: {
      label: "课程简介",
      prop: "courseIntroduction"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "800px"
    },
    attrs: {
      type: "textarea",
      rows: 5,
      placeholder: "请输入课程简介",
      maxlength: "400",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.courseIntroduction,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "courseIntroduction", $$v);
      },
      expression: "formData.courseIntroduction"
    }
  })], 1)], 1), _c("div", [_c("el-form-item", {
    attrs: {
      label: "课程介绍",
      prop: "courseContent"
    }
  }, [_c("div", {
    staticClass: "i-wangEditor",
    attrs: {
      id: "wangEditor"
    }
  })])], 1), _c("div", {
    staticStyle: {
      "text-align": "center",
      padding: "20px 0"
    }
  }, [_c("el-button", {
    attrs: {
      type: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.isSaveLoading
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("确定")])], 1)], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.overviewShow,
      expression: "!overviewShow"
    }]
  }, [_c("div", {
    staticClass: "course-list-text"
  }, [_vm._v("课程信息")]), _c("div", {
    staticClass: "mb-20"
  }, [_c("el-button", {
    attrs: {
      size: "small",
      plain: "",
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.addContent();
      }
    }
  }, [_vm._v("添加内容")]), _c("el-button", {
    attrs: {
      size: "small",
      disabled: _vm.importDisabled,
      plain: "",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.importFrame();
      }
    }
  }, [_vm._v("引入上节框架")])], 1), _vm._l(_vm.contentList, function (item, index) {
    return _c("div", {
      directives: [{
        name: "loading",
        rawName: "v-loading",
        value: _vm.contentLoading,
        expression: "contentLoading"
      }],
      key: index,
      staticClass: "content-list"
    }, [item.fileType === 1 ? _c("div", [_c("div", {
      staticClass: "content-item"
    }, [_c("div", {
      staticClass: "btnS"
    }, [_c("span", {
      staticClass: "type mb-15 mr-30"
    }, [_vm._v(_vm._s(item.contentName))]), _c("el-tooltip", {
      attrs: {
        content: "删除",
        placement: "top"
      }
    }, [_c("svg-icon", {
      attrs: {
        "icon-class": "delete"
      },
      on: {
        click: function click($event) {
          return _vm.delPDFOrVideo(item, index);
        }
      }
    })], 1)], 1), _c("el-upload", {
      directives: [{
        name: "loading",
        rawName: "v-loading",
        value: item.loading,
        expression: "item.loading"
      }],
      staticClass: "avatar-uploader",
      attrs: {
        action: "",
        "show-file-list": false,
        "http-request": function httpRequest(params) {
          return _vm.uploadImgOrAnnex(params, item, index);
        },
        "before-upload": function beforeUpload(params) {
          return _vm.beforeUpload(params, item, index);
        }
      }
    }, [item.contentUlrArr && item.contentUlrArr.length ? _c("img", {
      staticClass: "avatar",
      attrs: {
        src: item.fileType === 1 ? _vm.imgPDF : _vm.imgVideo
      }
    }) : _vm._e(), item.contentUlrArr && item.contentUlrArr.length ? _c("i", {
      staticClass: "el-icon-remove",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          item.contentUlrArr = [];
        }
      }
    }) : _vm._e(), _c("i", {
      staticClass: "el-icon-plus avatar-uploader-icon"
    }), item.percentageShow ? _c("el-progress", {
      attrs: {
        type: "circle",
        percentage: item.percentage,
        width: 108
      }
    }) : _vm._e()], 1), _c("span", {
      staticClass: "content-file-name"
    }, [_vm._v(_vm._s(item.contentUlrArr && item.contentUlrArr[0] && item.contentUlrArr[0].fileName ? item.contentUlrArr[0].fileName : ""))])], 1)]) : _vm._e(), item.fileType === 2 ? _c("div", {
      staticClass: "flex-box"
    }, [_c("div", {
      staticClass: "content-item",
      class: {
        active: item.fileType === 2
      }
    }, [_c("div", {
      staticClass: "btnS"
    }, [_c("span", {
      staticClass: "type mb-15 mr-30"
    }, [_vm._v(_vm._s(item.contentName))]), _c("el-tooltip", {
      attrs: {
        content: "删除",
        placement: "top"
      }
    }, [_c("svg-icon", {
      attrs: {
        "icon-class": "delete"
      },
      on: {
        click: function click($event) {
          return _vm.delPDFOrVideo(item, index);
        }
      }
    })], 1)], 1), _c("div", {
      staticClass: "content-img-list flex-box"
    }, [_vm._l(item.contentUlrArr, function (ite, n) {
      return _c("div", {
        key: "item-".concat(n),
        staticClass: "content-item-wrap"
      }, [_c("img", {
        staticClass: "avatar",
        attrs: {
          src: _vm.imgVideo
        }
      }), _c("i", {
        staticClass: "el-icon-remove",
        on: {
          click: function click($event) {
            return _vm.reduceImgOrAnnex(item, ite, n);
          }
        }
      }), _c("span", {
        staticClass: "file-img-name"
      }, [_vm._v(_vm._s(ite && ite.fileName ? ite.fileName : ""))])]);
    }), _c("el-upload", {
      directives: [{
        name: "loading",
        rawName: "v-loading",
        value: item.loading,
        expression: "item.loading"
      }],
      staticClass: "avatar-uploader",
      attrs: {
        action: "",
        "show-file-list": false,
        "http-request": function httpRequest(params) {
          return _vm.uploadImgOrAnnex(params, item, index);
        },
        "before-upload": function beforeUpload(params) {
          return _vm.beforeUpload(params, item, index);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-plus avatar-uploader-icon"
    }), item.percentageShow ? _c("el-progress", {
      attrs: {
        type: "circle",
        percentage: item.percentage,
        width: 108
      }
    }) : _vm._e()], 1)], 2)])]) : _vm._e(), item.fileType === 3 || item.fileType === 4 ? _c("div", {
      staticClass: "flex-box"
    }, [_c("div", {
      staticClass: "content-item",
      class: {
        active: item.fileType === 3 || item.fileType === 4
      }
    }, [_c("div", {
      staticClass: "btnS"
    }, [_c("span", {
      staticClass: "type mb-15 mr-30"
    }, [_vm._v(_vm._s(item.contentName))]), _c("el-tooltip", {
      attrs: {
        content: "删除",
        placement: "top"
      }
    }, [_c("svg-icon", {
      attrs: {
        "icon-class": "delete"
      },
      on: {
        click: function click($event) {
          return _vm.delPDFOrVideo(item, index);
        }
      }
    })], 1)], 1), _c("div", {
      staticClass: "content-img-list flex-box"
    }, [_vm._l(item.contentUlrArr, function (ite, n) {
      return _c("div", {
        key: "item-".concat(n),
        staticClass: "content-item-wrap"
      }, [_c("img", {
        staticClass: "avatar",
        attrs: {
          src: item.fileType === 3 ? ite.fileUrl : item.fileType === 2 ? _vm.imgVideo : _vm.imgAnnex
        }
      }), _c("i", {
        staticClass: "el-icon-remove",
        on: {
          click: function click($event) {
            return _vm.reduceImgOrAnnex(item, ite, n);
          }
        }
      }), _c("span", {
        staticClass: "file-img-name"
      }, [_vm._v(_vm._s(ite && ite.fileName ? ite.fileName : ""))])]);
    }), _c("el-upload", {
      directives: [{
        name: "loading",
        rawName: "v-loading",
        value: item.loading,
        expression: "item.loading"
      }],
      staticClass: "avatar-uploader",
      attrs: {
        action: "",
        "show-file-list": false,
        "http-request": function httpRequest(params) {
          return _vm.uploadImgOrAnnex(params, item, index);
        },
        "before-upload": function beforeUpload(params) {
          return _vm.beforeUpload(params, item, index);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-plus avatar-uploader-icon"
    }), item.percentageShow ? _c("el-progress", {
      attrs: {
        type: "circle",
        percentage: item.percentage,
        width: 108
      }
    }) : _vm._e()], 1)], 2)])]) : _vm._e()]);
  }), _c("div", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      disabled: _vm.saveBtnDisabled
    },
    on: {
      click: function click($event) {
        return _vm.updateCourse();
      }
    }
  }, [_vm._v("保存")])], 1)], 2)]), _c("form-wrap", {
    ref: "catalogueDialog",
    attrs: {
      title: _vm.catalogueTitle
    },
    on: {
      submit: _vm.submit
    }
  }), _c("form-wrap", {
    ref: "editCatalogueDialog",
    attrs: {
      title: "目录名称编辑"
    },
    on: {
      submit: _vm.sureEdit
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.contentDialog,
      width: "550px",
      "custom-class": "input-dialog",
      title: "输入内容信息"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.contentDialog = $event;
      },
      close: _vm.closeContentDialog
    }
  }, [_c("el-form", {
    ref: "contentForm",
    attrs: {
      "label-width": "120px",
      model: _vm.contentForm,
      rules: _vm.contentRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "内容名称：",
      prop: "contentName"
    }
  }, [_c("el-input", {
    attrs: {
      size: "small",
      placeholder: "请输入内容名称"
    },
    model: {
      value: _vm.contentForm.contentName,
      callback: function callback($$v) {
        _vm.$set(_vm.contentForm, "contentName", $$v);
      },
      expression: "contentForm.contentName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "文件类型：",
      prop: "fileType"
    }
  }, _vm._l(_vm.fileTypeTabs, function (item, i) {
    return _c("span", {
      key: i,
      staticClass: "content-tab-btn can-click mr-15",
      class: {
        active: item.active
      },
      on: {
        click: function click($event) {
          return _vm.chooseContentType(item);
        }
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.sureAddContent();
      }
    }
  }, [_vm._v("确定")])], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.uploadVideoDialog,
      width: "850px",
      "custom-class": "input-dialog",
      title: "视频上传",
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.uploadVideoDialog = $event;
      },
      close: _vm.closeVideoDialog
    }
  }, [_c("div", {
    staticClass: "formBox2"
  }, [_c("el-upload", {
    attrs: {
      multiple: "",
      action: "",
      "list-type": "picture-card",
      "http-request": function httpRequest(params) {
        return _vm.uploadVideoFun(params);
      },
      "before-upload": function beforeUpload(params) {
        return _vm.beforeUploadVideo(params);
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-plus avatar-uploader-icon"
  })])], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    }
  }, [_vm._v("关闭页面")])], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;