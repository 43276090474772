"use strict";

var _interopRequireDefault = require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearMoreSelect = clearMoreSelect;
exports.clearSelect = clearSelect;
exports.closeSelectedTag = closeSelectedTag;
exports.devTypeTransfrom = devTypeTransfrom;
exports.getSession = getSession;
exports.publicKey = publicKey;
exports.setSession = setSession;
exports.transformArea = transformArea;
exports.transformSearchArea = transformSearchArea;
exports.transformSelectArea = transformSelectArea;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _typeof2 = _interopRequireDefault(require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/typeof.js"));
var _store = _interopRequireDefault(require("../store"));
var _router = _interopRequireDefault(require("../router"));
function publicKey() {
  return 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCwjDm1HXDw8QH5ZtGMQIl2h/I8E+chOQA8aQ8xCR/+aHnROaN/ZU5Vmd2Zz7g6cAacR9BSm60+iSCYtvEGJKl0WqvbPGJkc8tedjNF1QqgWqkkuE6Udgw2OkEKJCxDg6PrAniR7Cc0io9G8bW4P8JDJjSbbafvMPDDFbVVUWJxxwIDAQAB';
}
/**
 * 数据保存到sessionStorage
 * @param key
 * @param value
 */
function setSession(key, value) {
  // console.log('key=', key)
  // console.log('value=', value)
  if (value === undefined || key === undefined) {
    return;
  }
  if (typeof value === 'number' || typeof value === 'string') {
    localStorage["".concat(key)] = value;
  } else if ((0, _typeof2.default)(value) === 'object' && value !== null) {
    localStorage["".concat(key)] = JSON.stringify(value);
  }
}

/**
 * 获取sessionStorage数据
 * @param key
 * @param key
 */
function getSession(key) {
  if (!localStorage["".concat(key)]) {
    return;
  }
  var value = localStorage["".concat(key)];
  var index1 = value.indexOf('[');
  var index2 = value.indexOf('{');
  if (index1 >= 0 || index2 >= 0) {
    value = JSON.parse(localStorage["".concat(key)]);
  }
  return value;
}

/**
 * 省市区搜索下拉列表
 * @param data
 */
function transformSearchArea(data) {
  var insertPart = {
    data: '全部',
    id: ''
  };
  data.unshift(insertPart);
  for (var i = 0; i < data.length; i++) {
    var province = data[i];
    province.label = province.data;
    province.value = province.id;
    if (province.children && province.children.length) {
      province.children.unshift(insertPart);
      for (var j = 0; j < province.children.length; j++) {
        var city = province.children[j];
        city.label = city.data;
        city.value = city.id;
        if (city.children && city.children.length) {
          city.children.unshift(insertPart);
          for (var k = 0; k < city.children.length; k++) {
            var area = city.children[k];
            area.label = area.data;
            area.value = area.id;
          }
        }
      }
    }
  }
  return data;
}
/**
 * 省市区非搜索下拉列表
 * @param data
 */
function transformSelectArea(data) {
  for (var i = 0; i < data.length; i++) {
    var province = data[i];
    province.label = province.data;
    province.value = province.id;
    if (province.children && province.children.length) {
      for (var j = 0; j < province.children.length; j++) {
        var city = province.children[j];
        city.label = city.data;
        city.value = city.id;
        if (city.children && city.children.length) {
          for (var k = 0; k < city.children.length; k++) {
            var area = city.children[k];
            area.label = area.data;
            area.value = area.id;
          }
        }
      }
    }
  }
  return data;
}

/**
 * 清空搜索条件
 * @param selectData
 * @param searchParam
 * @param vm
 */
function clearSelect(selectData, searchParam, vm) {
  if (selectData && Object.keys(selectData).length) {
    for (var item in selectData) {
      if (Array.isArray(selectData[item])) {
        selectData[item] = [];
      } else {
        selectData[item] = '';
      }
    }
  }
  if (searchParam && Object.keys(searchParam).length) {
    for (var ele in searchParam) {
      if (Array.isArray(searchParam[ele])) {
        searchParam[ele] = [];
      } else {
        if (ele !== 'currentPage' && ele !== 'pageSize' && ele !== 'warningLabel') {
          searchParam[ele] = '';
        }
      }
    }
  }
  vm.timeTitle = '全部时间';
}
/**
 * 清空搜索条件,搜索条件的key和更多选择中的key值一致，则清空时，将两个对象对应的值都清空
 * @param selectData
 * @param searchParam
 * @param vm
 */
function clearMoreSelect(moreSelectData, searchParam, vm) {
  if (moreSelectData && Object.keys(moreSelectData).length) {
    for (var item in moreSelectData) {
      if (Array.isArray(moreSelectData[item])) {
        moreSelectData[item] = [];
        searchParam[item] = [];
      } else {
        moreSelectData[item] = '';
        searchParam[item] = '';
      }
    }
  }
}
/**
 * 设备类型转换为中文
 * @param val
 * @returns {*}
 */
function devTypeTransfrom(val) {
  var devTypeMapDict = getSession('devTypeDict');
  if (devTypeMapDict[val]) {
    return devTypeMapDict[val];
  } else {
    return val;
  }
}

/**
 *  省市区的数据过滤
 * @param param
 */
function transformArea(param) {
  // data对象省市区的字段名必须为（areaCode）
  var data = JSON.parse(JSON.stringify(param));
  if (data.areaCode) {
    data.areaCode = data.areaCode[2] || data.areaCode[1] || data.areaCode[0];
  }
  // brandModel品牌型号参数拆分
  if (data.brandModel && data.brandModel.length === 2) {
    data.brand = data.brandModel[0].slice(2);
    data.model = data.brandModel[1].slice(2);
  }
  return data;
}

/**
 *  关闭当前页并返回上页（或指定页）
 * @param oldUrl
 * @param newUrl
 */
function closeSelectedTag(oldUrl, newUrl) {
  _store.default.dispatch('tagsView/delView', oldUrl).then(function () {
    newUrl ? _router.default.push(newUrl) : _router.default.go(-1);
  });
}