"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
/** 管理后台账户管理 */
var _default = exports.default = {
  /**
   * 账号密码登录
   */
  submitLogin: function submitLogin(data) {
    return (0, _http.POST)('/business/login/ajaxLogin', data);
  },
  /**
   * 修改密码
   */
  resetPwd: function resetPwd(data) {
    return (0, _http.POST)('/business/login/resetPwd', data);
  },
  /**
   * 短信修改密码
   */
  smsResetPwd: function smsResetPwd(data) {
    return (0, _http.POST)('/business/login/smsResetPwd', data);
  },
  /**
   * 手机号发送验证码（短信登录和短信修改密码）
   */
  sendSms: function sendSms(data) {
    return (0, _http.POST)('/business/login/sendSms', data);
  },
  /**
   * 短信登录
   */
  smsLogin: function smsLogin(data) {
    return (0, _http.POST)('/business/login/smsLogin', data);
  },
  /**
   * 菜单权限
  */
  getMenuByUserId: function getMenuByUserId(data) {
    return (0, _http.GET)('/business/login/getMenuByToken', data);
  },
  /**
   * 获取全部的角色及项目信息
  */
  findAllRoleList: function findAllRoleList(data) {
    return (0, _http.POST)('/business/bgUserGroup/findAllRoleList', data);
  }
};