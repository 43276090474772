"use strict";

var _interopRequireDefault = require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var apiFiles = require.context('./modules', false, /\.js$/);
var api = apiFiles.keys().reduce(function (modules, modulePath) {
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = apiFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
_vue.default.prototype.$api = api;