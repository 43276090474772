"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = require("@/utils/http");
/** 基础管理 */
var _default = exports.default = {
  /**
   * 课程分类列表
   */
  courseClassification: function courseClassification(data) {
    return (0, _http.POST)('/courseType/queryByPage', data);
  },
  /**
   * 课程管理
   */
  courseList: function courseList(data) {
    return (0, _http.POST)('/courseOverview/queryByPage', data);
  },
  /**
   * 课程树
   */
  courseTree: function courseTree(data) {
    return (0, _http.POST)('/courseChapter/queryTree', data);
  },
  /**
   * 课程总览详情
   */
  courseDetail: function courseDetail(data) {
    return (0, _http.POST)('/courseOverview/queryInfo', data);
  },
  /**
   * 课程详情--编辑
   */
  courseUpdate: function courseUpdate(data) {
    return (0, _http.POST)('/courseOverview/update', data);
  },
  /**
   * 获取oss短期认证信息
   */
  getOssAccessInfo: function getOssAccessInfo(data) {
    return (0, _http.POST)('/oss/getOssAccessInfo', data);
  },
  /**
   * 课程章节详情
   */
  courseChapterDetail: function courseChapterDetail(data) {
    return (0, _http.POST)('/courseChapter/queryInfo', data);
  },
  /**
   * 课程章节新增
   */
  courseChapterAdd: function courseChapterAdd(data) {
    return (0, _http.POST)('/courseChapter/insert', data);
  },
  /**
   * 课程章节名称编辑
   */
  courseChapterEdit: function courseChapterEdit(data) {
    return (0, _http.POST)('/courseChapter/update', data);
  },
  /**
   * 课程章节删除
   */
  courseChapterDel: function courseChapterDel(data) {
    return (0, _http.POST)("/courseChapter/deleteById?id=".concat(data.id));
  },
  /**
   * 课程列表查询
   */
  courseChapterInfo: function courseChapterInfo(data) {
    return (0, _http.POST)('/courseChapterInfo/queryList', data);
  },
  /**
   * 课程列表新增
   */
  courseDetailAdd: function courseDetailAdd(data) {
    return (0, _http.POST)('/courseChapterInfo/insertBatch', data);
  },
  /**
   * 课程列表编辑
   */
  courseDetailUpdate: function courseDetailUpdate(data) {
    return (0, _http.POST)('/courseChapterInfo/updateBatch', data);
  },
  /**
   * 课程内容删除
   */
  courseListDel: function courseListDel(data) {
    return (0, _http.POST)("/courseChapterInfo/deleteById?id=".concat(data.id));
  },
  /**
   * 课程类型——添加
   */
  courseTypeAdd: function courseTypeAdd(data) {
    return (0, _http.POST)('/courseType/insert', data);
  },
  /**
   * 课程类型——编辑
   */
  courseTypeEdit: function courseTypeEdit(data) {
    return (0, _http.POST)('/courseType/update', data);
  },
  /**
   * 课程类型——删除
   */
  courseTypeDel: function courseTypeDel(data) {
    return (0, _http.POST)("/courseType/deleteById?id=".concat(data.id));
  },
  /**
   * 课程总览——新增
   */
  courseOverviewAdd: function courseOverviewAdd(data) {
    return (0, _http.POST)('/courseOverview/insert', data, 'none');
  },
  /**
   * 课程总览——删除
   */
  courseOverviewDel: function courseOverviewDel(data) {
    return (0, _http.POST)("/courseOverview/deleteById?id=".concat(data.id));
  }
};