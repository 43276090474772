"use strict";

var _interopRequireDefault = require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _verification = require("@/utils/verification");
var _utils = require("@/utils");
var _jsencrypt = require("jsencrypt");
var _lodash = _interopRequireDefault(require("lodash"));
var _default = exports.default = {
  name: 'ChangeAccountPwd',
  props: ['show'],
  data: function data() {
    var _this = this;
    /* 验证确认密码和第一次密码相同 */
    var checkConfirmPwd = function checkConfirmPwd(rule, value, callback) {
      if (value === "") {
        callback(new Error('请再次输入登录密码!'));
      } else if (value !== _this.formData.loginPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      isShow: false,
      formData: {
        id: '',
        oldloginPwd: '',
        loginPwd: '',
        confirmPwd: ''
      },
      formRule: {
        oldloginPwd: [{
          required: true,
          message: '原始密码不能为空',
          trigger: 'blur'
        }
        // { pattern: REG.passWord, message: TEXT_MESSAGE.passWord, trigger: 'blur' }
        ],
        loginPwd: [{
          required: true,
          message: '新密码不能为空',
          trigger: 'blur'
        }, {
          pattern: _verification.REG.passWord,
          message: _verification.TEXT_MESSAGE.passWord,
          trigger: 'blur'
        }],
        confirmPwd: [{
          required: true,
          validator: checkConfirmPwd,
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.isShow = this.show;
  },
  methods: {
    /* dialog的提交 */submitForm: function submitForm() {
      var _this2 = this;
      var form = this.$refs.ruleForm;
      form.validate(function (valid) {
        if (valid) {
          var _this2$formData = _this2.formData,
            oldloginPwd = _this2$formData.oldloginPwd,
            loginPwd = _this2$formData.loginPwd;
          var key = (0, _utils.publicKey)();
          var encryptor = new _jsencrypt.JSEncrypt();
          encryptor.setPublicKey(key);
          var oldencrypted = encryptor.encrypt(oldloginPwd);
          var newencrypted = encryptor.encrypt(loginPwd);
          _this2.$api.business.resetPwd({
            projectId: _this2.$store.state.user.projectId,
            userId: (0, _utils.getSession)('userId'),
            loginPwd: newencrypted,
            oldloginPwd: oldencrypted
          }).then(function () {
            _this2.$message({
              self: _this2,
              message: '修改密码成功！',
              type: 'success'
            });
            _this2.closeDialog();
          });
        }
      });
    },
    /* 关闭ialog的子向父传值 */closeDialog: function closeDialog(val) {
      this.isShow = val;
      this.$emit('close');
    }
  }
};