"use strict";

var _interopRequireDefault = require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("D:/project/desheng-manage-web/node_modules/@babel/runtime/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("../views/layout"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);

/* Layout */

// constantRouter 代表那些不需要动态判断权限的路由
var constantRoutes = exports.constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/resetpwd',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/resetPwd'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/svgIcon',
  name: 'svgIcons',
  component: _layout.default,
  redirect: '/svgIcon/list',
  hidden: true,
  children: [{
    path: 'list',
    name: 'svgIconList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/svg-icons/index'));
      });
    },
    meta: {
      title: 'svg 图标'
    }
  }]
}, {
  path: '/basicsManage',
  name: 'basicsManage',
  component: _layout.default,
  redirect: '/basicsManage/index',
  hidden: false,
  children: [{
    path: 'index',
    name: 'basicsManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/basicsManage/index'));
      });
    },
    meta: {
      title: '基础管理'
    }
  }]
}, {
  path: '/courseManage',
  name: 'courseManage',
  component: _layout.default,
  meta: {
    title: '课程管理'
  },
  hidden: false,
  children: [{
    path: 'courseClassification',
    name: 'courseClassification',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/courseManage/classification'));
      });
    },
    meta: {
      title: '课程分类'
    }
  }, {
    path: 'courseManage',
    name: 'courseManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/courseManage/courseManage'));
      });
    },
    meta: {
      title: '课程管理'
    }
  }]
}, {
  path: '/courseDetail',
  name: 'courseDetail',
  component: _layout.default,
  redirect: '/courseDetail/index',
  hidden: true,
  children: [{
    path: 'index',
    name: 'courseDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/courseManage/courseDetail'));
      });
    },
    meta: {
      title: '课程详情'
    }
  }]
}, {
  path: '/roleManage',
  name: 'roleManage',
  component: _layout.default,
  redirect: '/roleManage/index',
  hidden: false,
  children: [{
    path: 'index',
    name: 'roleManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/roleManage/index'));
      });
    },
    meta: {
      title: '角色管理'
    }
  }]
}, {
  path: '/userManage',
  name: 'userManage',
  component: _layout.default,
  redirect: '/userManage/index',
  hidden: false,
  children: [{
    path: 'index',
    name: 'userManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userManage/index'));
      });
    },
    meta: {
      title: '成员管理'
    }
  }]
}, {
  path: '/evaluateManage',
  name: 'evaluateManage',
  component: _layout.default,
  redirect: '/evaluateManage/index',
  hidden: false,
  children: [{
    path: 'index',
    name: 'evaluateManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/evaluateManage/index'));
      });
    },
    meta: {
      title: '评论管理'
    }
  }]
}];
// asyncRouter 代表那些需求动态判断权限并通过 addRoutes 动态添加的页面。
var asyncRoutes = exports.asyncRoutes = {
  'heze': [],
  'fuzhou': []
};
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
router.$addRoutes = function (params) {
  // router.matcher = new Router({mode: 'history'}).matcher;
  router.matcher = createRouter().matcher;
  router.addRoutes(params);
};
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var originalPush = _vueRouter.default.prototype.push;
_vueRouter.default.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
var _default = exports.default = router;